<template>
  <div class="page-about">
    <div class="page-about__top">
      <div class="page-about__top_main">
        <div class="page-about__top_main_title">关于OneESG</div>
        <div class="page-about__top_main_line"></div>
        <div class="page-about__top_main_content">OneESG平台是为投资提供量化ESG数据服务的平台，核心目标是用一个财务化指标反映公司的ESG价值。平台由北京一标数字科技有限公司推出，公司创始人拥有二十年CSR／ESG研究经验，多年CSR／ESG价值量化研究和服务经验。</div>
      </div>
    </div>

    <div class="page-about__content">
      <div class="page-about__content_main">
        <div class="page-about__content_main_title">核心团队</div>
        <div class="page-about__content_main_main">
          <div class="item" v-for="s in teamList" :key="s.name">
            <div><img :src="require(`@/assets/${s.img}`)" alt=""></div>
            <div>{{s.name}}</div>
            <div>{{s.desc}}</div>
            <div>{{s.val}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-about__contast">
      <div class="page-about__contast_left">
        <div>联系我们</div>
        <div>联系我们，了解有关OneESG的更多信息</div>
      </div>
      <div class="page-about__contast_right">
        <div style="margin-bottom: 10px; color: #333; font-size: 16px;">手机</div>
        <div style="display: flex; margin-bottom: 20px; position: relative;">
          <el-input v-model="phone" placeholder=""></el-input><span @click="handleGetCode" style="position: absolute; right: 40px; display: inline-block; height: 44px; line-height: 44px; color: #416DF9; font-size: 16px; background: #fff; cursor: pointer;">{{smsCodeText}}</span>
          <!-- <el-input v-model="phone" placeholder=""></el-input><span @click="handleGetCode" style="display: inline-block; width: 120px; height: 44px;padding-right: 40px; line-height: 44px; color: #416DF9; font-size: 16px; background: #fff; cursor: pointer;">{{smsCodeText}}</span> -->
        </div>
        <div style="margin-bottom: 10px; color: #333; font-size: 16px;">验证码</div>
        <div style="display: flex; margin-bottom: 20px;">
          <el-input v-model="code" placeholder=""></el-input>
        </div>
        <div style="margin-bottom: 10px; color: #333; font-size: 16px;">留言</div>
        <div style="display: flex; margin-bottom: 20px;">
          <el-input type="textarea" v-model="msgVal" placeholder=""></el-input>
        </div>
        <div class="page-about__contast_right_agreement">
          <span>已阅读并同意</span>
          <span style="color: #416DF9; cursor: pointer;" @click="handleOpenAgreement">OneESG用户协议与隐私条款</span>
        </div>
        <div @click="handleCommit" class="page-about__contast_right-btn">提交</div>
      </div>
    </div>

    <div class="page-about__bottom">
      <div class="page-about__bottom_main">
        <div class="page-about__bottom_main-content">
          <div class="page-about__bottom_main-content_oneesg">
            <img :src="require('@/assets/header-logo.png')" alt="">
          </div>
          <div class="page-about__bottom_main-content_line"></div>
          <div style="display: inline-block">
            <div class="page-about__bottom_main-content_msg">用一个财务化指标反映ESG价值</div>
            <div class="page-about__bottom_main-content_email">扫码关注OneESG公众号，获取更多ESG信息，合作联系，账号开通请发送邮件至：contact@oneesg.cn</div>
          </div>
        </div>
        <div class="page-about__bottom_main-qr">
          <img :src="require('@/assets/qr.png')" alt="">
        </div>
      </div>
    </div>

    <div class="page-about__record">
      © 2022 oneesg.cn 版权所有 备案号：京ICP备2022018000号-2·京公网安备 11010802039640
    </div>
  </div>
</template>

<script>
import { sendSms, commit } from '@/request/fetch.js'
export default {
  name: 'AboutPage',
  props: {
    msg: String
  },
  data() {
    return {
      phone: '',
      code: '',
      msgVal: '',
      smsUuid: '',
      teamList: [
        { name: '殷格非', desc: '创始人兼CEO', img: 'one.png', val: '责扬天下管理顾问创始人，19年从业经历：金蜜蜂智库创始人、责扬天下首席专家、ISO TC322可持续金融国际标准对口工作组专家。中国CSR/ESG量化技术研究领先者：2017年研发推出责任竞争力量化技术，2018年首创企业单位净资产综合价值计算技术，研发ESG价值计算技术。' },
        { name: '吴福顺', desc: '联合创始人兼COO', img: 'two.png', val: '2003年参与金蜜蜂智库创建，从业经验跨ESG咨询、管理、技术及投资。2003-2011从事CSR/ESG咨询；2012-2018在蒙牛、美团负责CSR管理；2009年起，从事ESG技术及ESG投资，代表性项目有生态环保消费帮扶电商平台、消费扶贫技术服务平台、数字乡村产业服务平台。' },
        { name: '代奕波   ', desc: '联合创始人兼产品经理', img: 'three.png', val: '15年CSR/ESG工作经验。企业ESG战略、管理及信息披露专家，客户涵盖电力、ICT、交通运输、金融、医药、采掘等行业。《ESG竞争力》《ESG管理与信息披露实务》主编。' }
      ],
      smsNum: 60
    }
  },

  computed: {
    smsCodeText: function () {
      return this.smsNum === 60 ? `获取验证码` : `${this.smsNum}s后重新发送`
    },
  },

  mounted() {
  },

  methods: {
    handleGetSms: async function () {
      const ruleReg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (ruleReg.test(this.phone) && !this.smsBtnDisabled) {
        this.smsBtnDisabled = true
        let timer = setInterval(() => {
          --this.smsNum
        }, 1000)
        setInterval(() => {
          clearInterval(timer)
          this.smsNum = 60
          this.smsBtnDisabled = false
        }, 60000)
      }
    },
    handleOpenAgreement() {
      let originUrl = window.origin;
      window.open(`${originUrl}/OneESG用户协议与隐私条款.pdf`);
    },

    async handleGetCode() {
      if (!this.phone) {
        this.$notify({
          title: '请填写手机号',
          position: 'top-right',
          type: 'warning'
        });
        return
      }
      const ruleReg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!ruleReg.test(this.phone)) {
        this.$notify({
          title: '手机号格式不正确',
          position: 'top-right',
          type: 'warning'
        });
        return
      }
      const res = await sendSms({
        mobile: this.phone
      })
      if (res?.code === 0) {
        this.$notify({
          title: '验证码获取成功',
          position: 'top-right',
          type: 'warning'
        });
        this.smsUuid = res.data.smsUuid
        this.handleGetSms()
      } else if (res?.code === -1) {
        this.$notify({
          title: '获取验证码次数超出今日限制，请明天再试',
          position: 'top-right',
          type: 'warning'
        });
      }
    },

    async handleCommit() {
      if (!this.phone) {
        this.$notify({
          title: '请填写手机号',
          position: 'top-right',
          type: 'warning'
        });
        return
      }
      const ruleReg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!ruleReg.test(this.phone)) {
        this.$notify({
          title: '手机号格式不正确',
          position: 'top-right',
          type: 'warning'
        });
        return
      }
      if (!this.code) {
        this.$notify({
          title: '请填写验证码',
          position: 'top-right',
          type: 'warning'
        });
        return
      }
      if (!this.msgVal) {
        this.$notify({
          title: '请填写留言',
          position: 'top-right',
          type: 'warning'
        });
        return
      }
      if (!this.smsUuid) {
        this.$notify({
          title: '请先获取验证码',
          position: 'top-right',
          type: 'warning'
        });
        return
      }
      const res = await commit({
        mobile: this.phone,
        smsUuid: this.smsUuid,
        smsCode: this.code,
        leaveMessage: this.msgVal
      })
      if (res && res.status === 200) {
        console.log('success')
        this.msgVal = ''
        this.phone = ''
        this.code = ''
      }
      if (res?.message) {
        this.$notify({
          title: res?.message,
          position: 'top-right',
          type: 'warning'
        });
      }
    }
  }
}
</script>

<style scoped lang="stylus">
>>>.el-input__inner {
  border: none;
  height: 44px;
}

>>>.el-textarea__inner {
  border: none;
  height: 132px;
}

.page-about {
  text-align: left;

  &__record {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    text-align: center;
    padding: 20px 0 40px 0;
  }

  &__bottom {
    width: 100%;
    height: 289px;
    background: #FFFFFF;
    border-bottom: 1px solid #EEEEEE;

    &_main {
      width: 1400px;
      height: 100%;
      margin: 0 auto;
      background: pink;
      display: flex;
      justify-content: space-between;
      position: relative;
      background: #ffffff;
      border-radius: 10px;

      &-qr {
        width: 168px;
        height: 168px;
        position: absolute;
        top: 59px;
        right: 0;

        img {
          width: 100%;
        }
      }

      &-content {
        // margin: 111px 0 0 30px;
        position: absolute;
        display: flex;
        align-items: center;
        // top: 64px;
        height: 289px;

        &_line {
          width: 1px;
          height: 56px;
          background: #EEEEEE;
          border-radius: 1px;
          margin: 0 30px;
        }

        &_oneesg {
          display: inline-block;
          width: 88px;
          height: 30px;

          img {
            width: 100%;
          }
        }

        &_email {
          margin-top: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #333;
        }

        &_msg {
          font-size: 16px;
          font-weight: 400;
          color: #333;
        }
      }
    }
  }

  &__contast {
    width: 100%;
    height: 662px;
    background: #F5F6F9;
    margin-top: 100px;
    display: flex;
    justify-content: center;

    &_left {
      margin-right: 465px;
      width: 335px;
      margin-top: 100px;

      :nth-child(1) {
        margin-bottom: 17px;
        // width: 160px;
        height: 38px;
        font-size: 40px;
        font-weight: 600;
        color: #333333;
        line-height: 38px;
      }

      :nth-child(2) {
        // width: 335px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
    }

    &_right {
      width: 600px;
      margin-top: 100px;

      &_agreement {
        width: 303px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin-bottom: 20px;
      }

      &-btn {
        width: 86px;
        height: 44px;
        background: #416DF9;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 44px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  &__content {
    width: 100%;

    &_record {
      width: 1400px;
      height: 82px;
      border: 1px solid red;
    }

    &_main {
      width: 1400px;
      margin: 0 auto;

      &_title {
        padding-top: 100px;
        text-align: center;
        width: 160px;
        height: 56px;
        margin: 0 auto;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 56px;
      }

      &_main {
        width: 1400px;
        display: flex;
        justify-content: space-between;

        .item {
          width: 420px;
          height: 506px;
          padding-top: 40px;
          background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFF 100%);
          border-radius: 4px;
          margin: 50px 0 0 0;
          width: 420px;
          border-bottom: 4px solid #416DF9;

          :nth-child(1) {
            margin: 0 auto;
            width: 118px;
            height: 118px;

            // padding: 20px 0 10px 0;
            img {
              width: 100%;
            }
          }

          :nth-child(2) {
            padding: 20px 0 10px 0;
            margin: 0 auto;
            width: 78px;
            height: 36px;
            font-size: 26px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 36px;
          }

          :nth-child(3) {
            margin: 0 auto;
            // width: 124px;
            text-align: center;
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28px;
          }

          :nth-child(4) {
            padding-top: 40px;
            text-align: center;
            margin: 0 auto;
            width: 320px;
            height: 224px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 32px;
          }
        }
      }
    }
  }

  &__top {
    width: 100%;
    height: 400px;
    background: url('../assets/about-bgc.png') center center / 1920px 400px no-repeat;
    background-size: cover;

    &_main {
      margin: 0 auto;
      width: 1400px;

      &_title {
        text-align: center;
        height: 58px;
        font-size: 42px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 58px;
        letter-spacing: 1px;
        padding: 86px 0 40px 0;
      }

      &_line {
        width: 68px;
        height: 4px;
        background: #FFFFFF;
        margin: 0 auto;
      }

      &_content {
        padding-top: 60px;
        margin: 0 auto;
        width: 980px;
        height: 76px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 38px;
      }
    }
  }
}
</style>
