import Vue from 'vue'
import Router from 'vue-router'
import About from '../components/about.vue'
import Esg from '../components/esg.vue'
import Home from '../components/home.vue'

Vue.use(Router)

export const routes = [
  {
    path: '/about',
    component: About,
    meta: 3
  },
  {
    path: '/esg',
    component: Esg,
    meta: 2
  },
  {
    path: '/home',
    component: Home,
    meta: 1
  },
  { path: '/*', redirect: '/home' }
]

export default new Router({
  // mode: 'hash',
  mode: 'history',
  // scrollBehavior: () => ({ y: 0 }),
  routes: routes
})
