<template>
  <div class="page-header">
    <div class="page-header__logo">
      <img :src="require('@/assets/header-logo.png')" alt="">
    </div>
    <div class="page-header__tabs">
      <div class="tabs">
        <div class="tabs__item" @click="handleSwitchTab(s.value)" v-for="s in tabList" :key="s.value" :class="{ 'active': currTab === s.value }" :ref="s.value">
          {{ s.label }}
        </div>
        <div class="tabs__active" :style="activeTabStyle"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderPage',
  props: {
    msg: String
  },
  data() {
    return {
      activeLeft: 0,
      activeWidth: 30,
      tabWidth: 76,
      tabList: [
        { label: '首页', value: 1 },
        { label: 'ESG洞察', value: 2 },
        { label: '关于我们', value: 3 },
        { label: '登录', value: 4 }
      ],
      currTab: 1
    }
  },

  mounted() {
    this.currTab = this.$route.meta
    this.$nextTick(() => {
      this.activeLeft = this.$refs[this.currTab][0]?.offsetLeft
      this.activeWidth = this.$refs[this.currTab][0]?.offsetWidth - this.tabWidth
    })
  },

  methods: {
    handleSwitchTab(val) {
      this.$emit('headerTabChange', val)
      if (val === 4) return
      this.currTab = val
      this.$nextTick().then(() => {
        this.activeLeft = this.$refs[val][0]?.offsetLeft
        this.activeWidth = this.$refs[val][0]?.offsetWidth - this.tabWidth
      })
    }
  },

  computed: {
    activeTabStyle() {
      return `left: ${this.activeLeft}px; transform: translateX(${this.activeWidth / 2}px);`
    }
  }
}
</script>

<style scoped lang="stylus">
.page-header {
  height: 80px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: 1400px;
  display: flex;
  align-items: center;
  margin: 0 auto;

  &__logo {
    width: 94px;
    height: 32px;

    img {
      width: 100%;
    }
  }

  &__tabs {
    span {
      line-height: 60px;
      margin-right: 60px;
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      line-height: 25px;
    }
  }
}

.tabs {
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: left;

  &__item {
    font-size: 18px;
    font-weight: 600;
    color: #999999;
    margin-right: 60px;
    cursor: pointer;
    width: 74px;
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
  }

  &__item:hover {
    color: #416DF9;
  }

  &__active {
    position: absolute;
    width: 30px;
    height: 4px;
    background: #406df9;
    border-radius: 2px;
    top: -28px;
    transition: all linear 0.3s;
    width: 50px;
    height: 4px;
    background: #FFFFFF;
    border-radius: 2px;
    width: 74px;
    height: 4px;
    background: #416DF9;
    border-radius: 2px;
  }

  .active {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #416DF9;
    line-height: 25px;
  }
}
</style>
