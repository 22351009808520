<template>
  <div class="tabs">
    <div class="tabs__item" @click="handleSwitchTab(s.code)" v-for="s in tabList" :key="s && s.code" :class="{ 'active': currTab === s && s.code }" :ref="s && s.code">
      {{ s && s.name || '' }}
    </div>
    <div class="tabs__active" :style="activeTabStyle"></div>
  </div>
</template>

<script>
export default {
  name: "EsgTab",
  props: {
    tabList: {
      type: Array,
      default: () => []
    },

    currTab: {
      type: String,
      default: 'esg_research'
    }
  },

  data() {
    return {
      activeLeft: 0,
      activeWidth: 30,
      tabWidth: 50
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.activeLeft = this.$refs[this.currTab][0]?.offsetLeft
      this.activeWidth = this.$refs[this.currTab][0]?.offsetWidth - this.tabWidth
    })
  },

  methods: {
    handleSwitchTab(val) {
      this.$emit('tabChange:currTab', val)
      this.$nextTick().then(() => {
        this.activeLeft = this.$refs[val][0]?.offsetLeft
        this.activeWidth = this.$refs[val][0]?.offsetWidth - this.tabWidth
      })

    }
  },

  computed: {
    activeTabStyle() {
      return `left: ${this.activeLeft}px; transform: translateX(${this.activeWidth / 2}px);`
    }
  },

  watch: {
    currTab(n) {
      this.handleSwitchTab(n)
    }
  }
}

</script>

<style lang="stylus" scoped>
.tabs {
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: left;
  text-align: center;

  &__item {
    font-size: 18px;
    font-weight: 600;
    color: #999999;
    margin-right: 100px;
    cursor: pointer;
    width: 97px;
    height: 36px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
  }

  &__active {
    position: absolute;
    width: 30px;
    height: 4px;
    background: #406df9;
    border-radius: 2px;
    bottom: -18px;
    transition: all linear 0.2s;
    width: 50px;
    height: 4px;
    background: #FFFFFF;
    border-radius: 2px;
  }

  .active {
    color: #333333;
    width: 98px;
    height: 36px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 36px;
  }
}
</style>