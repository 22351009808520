import request from './request';
// 获取验证码
export function sendSms(data) {
  return request({
    url: '/auth/send/sms',
    method: 'post',
    data
  })
}
// 提交
export function commit(params) {
  return request({
    url: '/api/oneesg/leave/message',
    method: 'post',
    data: params
  })
}
export function getNewsTab(params) {
  return request({
    url: `/api/cms-news/news/channel`,
    method: 'get',
    params
  })
}
export function getNewsList(params) {
  return request({
    url: `/api/cms-news/website/query`,
    method: 'post',
    data: params
  })
}
export function getS() {
  return request({
    url: '/api/oneesg/website/portfolio',
    method: 'get'
  })
}
