<template>
  <div class="page-home" v-loading="loading" @click="tipsLogin = false">
    <div class="block">
      <el-carousel ref="bo" trigger="click" height="500px" arrow="never" :interval="4000">
        <el-carousel-item class="carousel-item">
          <!-- <img style="width: 100%" :src="require('@/assets/1.png')" alt=""> -->
          <div class="carousel-item__content">
            <div>2023中国上市公司协会ESG价值核算报告</div>
            <div></div>
            <div>ESG价值核算助力全球可持续金融发展</div>
            <div @click="downPdf" style="color: #fff; background-color: #f5ba0d; cursor: pointer; font-size: 16px; width: 200px; height: 42px; line-height: 42px; text-align: center; border-radius: 4px; margin-top: 15px">立即下载</div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="carousel-items">
          <!-- <img style="width: 100%" :src="require('@/assets/2.png')" alt=""> -->
          <div class="carousel-items__content">
            <div>更可靠的ESG数据：</div>
            <div>独家ESG数据校验模型确保定义准确</div>
            <div></div>
            <div>质量可靠、同业可比</div>
          </div>
        </el-carousel-item>
        <div style="width: 1400px; height: 500px; overflow: hidden; margin: 0 auto">
          <el-carousel-item class="carousel-itemss">
            <!-- <img style="width: 100%" :src="require('@/assets/3.png')" alt=""> -->
            <div class="carousel-itemss__content">
              <div>为ESG定价：依托20年CSR/ESG咨询经</div>
              <div>验，核算企业ESG外部化的成本和价值</div>
              <div></div>
              <div>将非财务的ESG因素财务化</div>
            </div>
          </el-carousel-item>
        </div>
      </el-carousel>
    </div>

    <div class="page-home__two">
      <div class="page-home__two_left">
        <div class="page-home__two_left_title">领先的ESG量化数据服务平台</div>
        <div class="page-home__two_left_line"></div>
        <div class="page-home__two_left_content">
          OneESG以中国上市公司ESG定量数据为基础，核算ESG外部化机遇和风险的财务价值，包括ESG总价值、ESG议题价值、ESG机遇显现值和风险暴露值等。核算全过程的数据均受到质量评估和监测，核算结果不设主观评价体系，使用真实ESG数据和价值化方法直观展现公司ESG价值。
        </div>
      </div>
      <div class="page-home__two_right">
        <img :src="require('@/assets/home-two.png')" alt="" />
      </div>
    </div>

    <div class="page-home__three">
      <div class="page-home__three_main">
        <div class="page-home__three_main_top">
          <div class="page-home__three_main_top_item" v-for="(s, i) in threeList" :key="i">
            <div>{{ s.value }}</div>
            <div>{{ s.label }}</div>
            <div v-if="i !== 4"></div>
          </div>
        </div>
        <div class="page-home__three_main_title">解决方案</div>
        <div class="page-home__three_main_bottom">
          <div class="page-home__three_main_bottom_item" v-for="s in threesList" :key="s">
            <div><img :src="require(`@/assets/${s.img}`)" alt="" /></div>
            <div>{{ s.title }}</div>
            <div>{{ s.label }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-home__four">
      <!-- <img :src="require('@/assets/home-four.png')" alt=""> -->
      <div class="page-home__four_main">
        <div class="page-home__four_main_title">应用场景</div>
        <div class="page-home__four_main_content">
          <div class="page-home__four_main_content_img"><img :src="require('@/assets/home-fourin.png')" alt="" /></div>
          <div class="page-home__four_main_content_text">
            <div v-for="s in fourList" :key="s" class="page-home__four_main_content_text_item">
              <div class="page-home__four_main_content_text_item_title"><span></span>{{ s.title }}</div>
              <div class="page-home__four_main_content_text_item_item" v-for="sin in s.child" :key="sin">{{ sin }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-home__combination">
      <div class="page-home__combination-name">ESG投资组合示例</div>
      <!-- <div class="page-home__combination-toast">
        
        <div class="page-home__combination-toast_img" v-if="tipsLogin">
          <div class="page-home__combination-toast_img_message">
          <span>登录后可查看组合详情，</span>
         <span style="color: #416DF9; cursor: pointer" @click="handleTipsLoginColose">登录</span>
        </div>
          <img :src="require('@/assets/home-toast.png')" alt=""></div>
      </div> -->
      <div class="page-home__combination-content">
        <div class="page-home__combination-content__list" v-for="s in data" :key="s.id" @click.stop="handleTipsLogin(s.id)">
          <div class="page-home__combination-content__list_toast" v-if="tipsLogin && curCombination === s.id">
            <div class="page-home__combination-content__list_toast_message">
              <span>登录后可查看组合详情，</span>
              <span style="color: #416df9; cursor: pointer" @click.stop="handleTipsLoginColose">登录</span>
            </div>
          </div>
          <div class="page-home__combination-content__list_img">
            <img :src="s.coverUrl" alt="" />
          </div>
          <div class="page-home__combination-content__list_title">{{ s.name }}</div>
          <div class="page-home__combination-content__list_earnings"><span></span><span class="earin">累计收益</span><span></span></div>
          <div class="page-home__combination-content__list_percent">
            <div class="page-home__combination-content__list_percent-top">
              <span style="float: left">{{ '本组合：' }}</span>
              <span v-if="s && s.nav" :style="{ float: 'right', 'font-size': '16px', 'font-weight': 600, color: s.nav - 0 < 0 ? '#00C087' : '#FC4F4F' }"
                ><span>{{ s.nav - 0 > 0 ? '+' : '' }}</span
                >{{ s && s.nav && !isNaN(Number(s.nav)) && Number(s.nav).toFixed(2) }}%</span
              >
              <span v-if="!(s && s.nav)">-</span>
            </div>
            <div class="page-home__combination-content__list_percent-bottom">
              <span style="float: left">{{ '跑赢上证：' }}</span>
              <span v-if="s && s.stockShNav" :style="{ float: 'right', 'font-size': '16px', 'font-weight': 600, color: s.stockShNav < 0 ? '#00C087' : '#FC4F4F' }"
                ><span>{{ s.stockShNav - 0 > 0 ? '+' : '' }}</span
                >{{ s && s.stockShNav && !isNaN(Number(s.stockShNav)) && Number(s.stockShNav).toFixed(2) }}%</span
              >
              <span v-if="!(s && s.stockShNav)">-</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-home__five">
      <div class="page-home__five_title">OneESG数据库</div>
      <div class="page-home__five_main">
        <div class="page-home__five_main_item" v-for="s in fiveList" :key="s">
          <div class="page-home__five_main_item_img"><img :src="require(`@/assets/${s.img}.png`)" alt="" /></div>
          <div class="page-home__five_main_item_content">
            <div>{{ s.title }}</div>
            <div>{{ s.label }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-home__contast">
      <div class="page-home__contast_left">
        <div>联系我们</div>
        <div>联系我们，了解有关OneESG的更多信息</div>
      </div>
      <div class="page-home__contast_right">
        <div style="margin-bottom: 10px; color: #333; font-size: 16px">手机</div>
        <div style="display: flex; margin-bottom: 20px; position: relative">
          <el-input v-model="phone" placeholder=""></el-input
          ><span @click="handleGetCode" style="position: absolute; right: 40px; display: inline-block; height: 44px; line-height: 44px; color: #416df9; font-size: 16px; background: #fff; cursor: pointer">{{ smsCodeText }}</span>
        </div>
        <div style="margin-bottom: 10px; color: #333; font-size: 16px">验证码</div>
        <div style="display: flex; margin-bottom: 20px">
          <el-input v-model="code" placeholder=""></el-input>
        </div>
        <div style="margin-bottom: 10px; color: #333; font-size: 16px">留言</div>
        <div style="display: flex; margin-bottom: 20px">
          <el-input type="textarea" v-model="msgVal" placeholder=""></el-input>
        </div>
        <div class="page-home__contast_right_agreement">
          <span>已阅读并同意</span>
          <span style="color: #416df9; cursor: pointer" @click="handleOpenAgreement">OneESG用户协议与隐私条款</span>
        </div>
        <div @click="handleCommit" class="page-home__contast_right-btn">提交</div>
      </div>
    </div>

    <div class="page-home__bottom">
      <div class="page-home__bottom_main">
        <div class="page-home__bottom_main-content">
          <div class="page-home__bottom_main-content_oneesg">
            <img :src="require('@/assets/header-logo.png')" alt="" />
          </div>
          <div class="page-home__bottom_main-content_line"></div>
          <div style="display: inline-block">
            <div class="page-home__bottom_main-content_msg">用一个财务化指标反映ESG价值</div>
            <div class="page-home__bottom_main-content_email">扫码关注OneESG公众号，获取更多ESG信息，合作联系，账号开通请发送邮件至：contact@oneesg.cn</div>
          </div>
        </div>
        <div class="page-home__bottom_main-qr">
          <img :src="require('@/assets/qr.png')" alt="" />
        </div>
      </div>
    </div>

    <div class="page-home__record">© 2022 oneesg.cn 版权所有 备案号：京ICP备2022018000号-2·京公网安备 11010802039640</div>

    <!-- <el-dialog
        :visible.sync="tipsLogin"
        width="13%"
        :show-close="false"
        close-on-click-modal="true"
        center>
        <span>登录后可查看组合详情，</span>
        <span style="color: #416DF9; cursor: pointer" @click="handleTipsLoginColose">登录</span>
      </el-dialog> -->
  </div>
</template>

<script>
import { getS, sendSms, commit } from '@/request/fetch'
export default {
  name: 'HomePage',
  data() {
    return {
      tipsLogin: false,
      loading: false,
      phone: '',
      code: '',
      msgVal: '',
      smsUuid: '',
      data: [],
      threeList: [
        { label: 'A股和香港联交所中资股公司', value: '6000+' },
        { label: '高实质性的底层定量数据', value: '80+' },
        { label: 'ESG因素价值化算法模型', value: '20+' },
        { label: '资深ESG分析师', value: '100+' },
        { label: '中国企业（组织）CSR/ESG报告', value: '22600+' }
      ],
      threesList: [
        { label: '自动采集企业公开披露的定量ESG数据，资深分析师多维度标记数据质量', img: 'home-three1.png', title: '底层数据采集' },
        {
          label: '为每个底层数据指标建立适合的数据补全模型，对缺失数据进行赋值，并通过机器学习方法不断优化校准',
          img: 'home-three2.png',
          title: '缺失数据补全'
        },
        {
          label: '沉淀多年CSR／ESG咨询经验，建立ESG价值量化算法模型，将ESG因素进行定价，助力投资者将ESG表现融入估值体系',
          img: 'home-three3.png',
          title: '价值化算法'
        },
        {
          label: '制定全流程数据质量管理体系，数据产品均配有可溯质量评级标签，确保底层数据一致、准确、有效、可比，价值化算法权威、及时、充分反映外部性影响',
          img: 'home-three4.png',
          title: '数据质量保证'
        }
      ],
      fourList: [
        {
          title: 'ESG投资工作台',
          child: [
            '跟踪持有投资组合的ESG价值表现，根据需要调整持仓，确保ESG风险可控；',
            '根据ESG价值筛选条件，建立ESG价值优选组合，回测收益；',
            '专属ESG投资管理工作台，支持将各类主体（如潜在投资标的、信贷对象、分子公司、合作伙伴等）ESG数据导入OneESG平台，自动生成ESG价值结果，支持ESG决策与管理。'
          ]
        },
        {
          title: '行业ESG基准线',
          child: ['用一组财务数据直观展现公司ESG的基本面：ESG价值、ESG机遇显现值／风险敞口值、环境因素的机遇与风险、社会因素的机遇与风险、ESG市盈率。']
        },
        {
          title: '公司ESG基本面',
          child: ['根据企业ESG价值结果，核算各行业ESG价值基准水平，助力投资者跟踪各行业ESG价值创造的变化趋势，选出兼顾投资回报和ESG价值的“绿色”行业、规避可能存在ESG风险的“灰色”行业；选出行业内更具ESG价值创造能力的“好企业”。']
        }
      ],
      fiveList: [
        {
          label: 'A股上市公司和H股中资上市公司的创造的ESG净价值核算结果数据，包括ESG净值、环境因素、社会因素的净值、每股ESG净值、单位营收ESG净值等。',
          img: 'five-one',
          title: 'ESG净值数据'
        },
        {
          label: '以行业ESG净值平均值为基准，用样本公司净值减去行业基准，得出正值为机遇显现值，负值为风险敞口值。',
          img: 'five-two',
          title: 'ESG机遇与风险数据'
        },
        { label: '以本数据库中的所有样本公司为基础，计算出各行业的ESG净值基准数据。', img: 'five-three', title: '行业ESG数据' },
        { label: 'A股上市公司和H股中资上市公司的ESG报告与财报中披露的定量ESG数据。', img: 'five-four', title: 'ESG底层数据' }
      ],
      curCombination: 0,
      smsNum: 60,
      smsBtnDisabled: false
    }
  },
  computed: {
    smsCodeText: function () {
      return this.smsNum === 60 ? `获取验证码` : `${this.smsNum}s后重新发送`
    }
  },
  async mounted() {
    this.loading = true
    const res = await getS()
    console.log(res)
    if (res?.item?.length) {
      const arr = ['https://api.oneesg.cn/file/r/images/chance_top10_0619.jpg', 'https://api.oneesg.cn/file/r/images/esg_top10_0619.jpg', 'https://api.oneesg.cn/file/r/images/portfolio4.jpg', 'https://api.oneesg.cn/file/r/images/portfolio3.jpg']
      this.data = [...res?.item]?.slice(0, 4)
      this.data?.map((s, i) => {
        s.coverUrl = arr[i]
      })
    }
    this.loading = false
  },
  methods: {
    downPdf() {
      window.open('https://oneesg-public.oss-cn-beijing.aliyuncs.com/file/%E4%B8%AD%E5%9B%BD%E4%B8%8A%E5%B8%82%E5%85%AC%E5%8F%B8ESG%E4%BB%B7%E5%80%BC%E6%A0%B8%E7%AE%97%E6%8A%A5%E5%91%8A%282023%29.pdf')
    },

    handleTipsLogin(id) {
      // const h = this.$createElement;
      // this.$msgbox({
      //     showConfirmButton: false,
      //     message: h('div', {style: {'text-align': 'center', 'margin-top': '10px', 'font-size': '16px'}}, [
      //       h('span', { style: { color: '#333'}}, '登录后可查看组合详情，'),
      //       h('span', { style: {color: '#416DF9', cursor: 'pointer'}, on: {click: () => {window.open('http://app.uat.oneesg.cn/home/index');  this.$refs.bo.click()}} }, '登录' )
      //     ])
      // })
      this.tipsLogin = true
      this.curCombination = id
    },
    handleTipsLoginColose() {
      this.tipsLogin = false
      window.open('https://app.oneesg.cn?loginState=1')
    },

    handleGetSms: async function () {
      const ruleReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (ruleReg.test(this.phone) && !this.smsBtnDisabled) {
        this.smsBtnDisabled = true
        let timer = setInterval(() => {
          --this.smsNum
        }, 1000)
        setInterval(() => {
          clearInterval(timer)
          this.smsNum = 60
          this.smsBtnDisabled = false
        }, 60000)
      }
    },

    handleOpenAgreement() {
      let originUrl = window.origin
      window.open(`${originUrl}/OneESG用户协议与隐私条款.pdf`)
    },

    async handleGetCode() {
      if (!this.phone) {
        this.$notify({
          title: '请填写手机号',
          position: 'top-right',
          type: 'warning'
        })
        return
      }
      const ruleReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!ruleReg.test(this.phone)) {
        this.$notify({
          title: '手机号格式不正确',
          position: 'top-right',
          type: 'warning'
        })
        return
      }

      const res = await sendSms({
        mobile: this.phone
      })
      if (res?.code === 0) {
        this.$notify({
          title: '验证码获取成功',
          position: 'top-right',
          type: 'warning'
        })
        this.smsUuid = res.data.smsUuid
        this.handleGetSms()
      } else if (res?.code === -1) {
        this.$notify({
          title: '获取验证码次数超出今日限制，请明天再试',
          position: 'top-right',
          type: 'warning'
        })
      }
    },

    async handleCommit() {
      if (!this.phone) {
        this.$notify({
          title: '请填写手机号',
          position: 'top-right',
          type: 'warning'
        })
        return
      }
      const ruleReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!ruleReg.test(this.phone)) {
        this.$notify({
          title: '手机号格式不正确',
          position: 'top-right',
          type: 'warning'
        })
        return
      }
      if (!this.code) {
        this.$notify({
          title: '请填写验证码',
          position: 'top-right',
          type: 'warning'
        })
        return
      }
      if (!this.msgVal) {
        this.$notify({
          title: '请填写留言',
          position: 'top-right',
          type: 'warning'
        })
        return
      }
      if (!this.smsUuid) {
        this.$notify({
          title: '请先获取验证码',
          position: 'top-right',
          type: 'warning'
        })
        return
      }
      const res = await commit({
        mobile: this.phone,
        smsUuid: this.smsUuid,
        smsCode: this.code,
        leaveMessage: this.msgVal
      })
      if (res && res.status === 200) {
        this.msgVal = ''
        this.phone = ''
        this.code = ''
      }
      if (res?.message || res?.msg) {
        this.$notify({
          title: res?.message || res?.msg,
          position: 'top-right',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
>>>.el-dialog__header {
  padding: 0;
}

>>>.el-input__inner {
  border: none;
  height: 44px;
}

>>>.el-textarea__inner {
  border: none;
  height: 132px;
}

>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #416DF9 !important;
}

// >>>.el-carousel__button {
// width: 44px;
// height: 4px;
// background: #FFFFFF !important;
// }
// >>>.el-carousel__indicator.is-active button {
// width: 44px;
// height: 4px;
// background: #416DF9 !important;
// }
>>>.el-carousel__indicator--horizontal {
  padding: 40px 4px;
}

.carousel-itemss {
  width: 100%;
  // width: 1920px;
  height: 500px;
  background: url('../assets/3.png') center center / 1920px 500px no-repeat;
  // background-size: cover;
  background-color: #E7EEFF !important;

  // background: linear-gradient(to right, rgb(211, 224, 245), rgb(211, 224, 245) 50%, rgba(222, 234, 250, 0)) left center / calc(100% - 1920px) 100% no-repeat linear-gradient(to left, rgb(213, 226, 246), rgb(213, 226, 246) 50%, rgba(217, 227, 246, 0)) right center / calc(100% - 1920px) 100% no-repeat url('../assets/3.png') center center / 1920px 520px no-repeat);
  &__content {
    width: 1400px;
    margin: 0 auto;
    padding-top: 146px;
    font-family: PingFangSC-Regular, PingFang SC;
  }

  :nth-child(1) {
    // width: 828px;
    height: 67px;
    font-size: 48px;
    font-weight: 600;
    color: #333333;
    line-height: 67px;
    letter-spacing: 1px;
  }

  :nth-child(2) {
    // width: 828px;
    height: 67px;
    font-size: 48px;
    font-weight: 600;
    color: #333333;
    line-height: 67px;
    letter-spacing: 1px;
  }

  :nth-child(3) {
    width: 68px;
    height: 4px;
    background: #416DF9;
    margin: 40px 0;
  }

  :nth-child(4) {
    // width: 532px;
    height: 40px;
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }
}

.carousel-items {
  width: 100%;
  height: 500px;
  background: url('../assets/2.png') center center / 1920px 500px no-repeat;
  // background-size: cover;
  font-family: PingFangSC-Regular, PingFang SC;
  background-color: #E7EEFF !important;

  &__content {
    width: 1400px;
    margin: 0 auto;
    padding-top: 114px;
  }

  :nth-child(1) {
    // width: 828px;
    height: 67px;
    font-size: 48px;
    font-weight: 600;
    color: #333333;
    line-height: 67px;
    letter-spacing: 1px;
  }

  :nth-child(2) {
    // width: 828px;
    height: 67px;
    font-size: 48px;
    font-weight: 600;
    color: #333333;
    line-height: 67px;
    letter-spacing: 1px;
  }

  :nth-child(3) {
    width: 68px;
    height: 4px;
    background: #416DF9;
    margin: 40px 0;
  }

  :nth-child(4) {
    // width: 532px;
    height: 40px;
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }
}

.carousel-item {
  width: 100%;
  height: 500px;
  background: url('../assets/1.png') center center / 1920px 500px no-repeat;
  // background-size: cover;
  background-color: #E7EEFF !important;
  background-color: pink;
  font-family: PingFangSC-Regular, PingFang SC;

  &__content {
    width: 1400px;
    margin: 0 auto;
    padding-top: 146px;
  }

  :nth-child(1) {
    height: 67px;
    font-size: 48px;
    font-weight: 600;
    color: #333333;
    line-height: 67px;
    letter-spacing: 1px;
  }

  :nth-child(2) {
    width: 68px;
    height: 4px;
    background: #416DF9;
    margin: 40px 0;
  }

  :nth-child(3) {
    height: 40px;
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }
}

.page-home {
  width: 100%;
  background: #fff;
  text-align: left;

  &__record {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    text-align: center;
    padding: 20px 0 40px 0;
  }

  &__bottom {
    width: 100%;
    height: 289px;
    background: #FFFFFF;
    border-bottom: 1px solid #EEEEEE;

    &_main {
      width: 1400px;
      height: 100%;
      margin: 0 auto;
      background: pink;
      display: flex;
      justify-content: space-between;
      position: relative;
      background: #ffffff;
      border-radius: 10px;

      &-qr {
        width: 168px;
        height: 168px;
        position: absolute;
        top: 59px;
        right: 0;

        img {
          width: 100%;
        }
      }

      &-content {
        position: absolute;
        display: flex;
        align-items: center;
        // top: 64px;
        height: 289px;

        &_line {
          width: 1px;
          height: 56px;
          background: #EEEEEE;
          border-radius: 1px;
          margin: 0 30px;
        }

        &_oneesg {
          display: inline-block;
          width: 88px;
          height: 30px;

          img {
            width: 100%;
          }
        }

        &_email {
          margin-top: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #333;
        }

        &_msg {
          font-size: 16px;
          font-weight: 400;
          color: #333;
        }
      }
    }
  }

  &__contast {
    width: 100%;
    height: 662px;
    background: #F5F6F9;
    margin-top: 100px;
    display: flex;
    justify-content: center;

    &_left {
      margin-right: 465px;
      width: 335px;
      margin-top: 100px;

      :nth-child(1) {
        margin-bottom: 17px;
        // width: 160px;
        height: 38px;
        font-size: 40px;
        font-weight: 600;
        color: #333333;
        line-height: 38px;
      }

      :nth-child(2) {
        // width: 335px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
    }

    &_right {
      width: 600px;
      margin-top: 100px;

      &_agreement {
        width: 303px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin-bottom: 20px;
      }

      &-btn {
        width: 86px;
        height: 44px;
        background: #416DF9;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 44px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  &__five {
    width: 1400px;
    margin: 0 auto;

    &_title {
      height: 56px;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
      line-height: 56px;
      text-align: center;
      margin-top: 100px;
    }

    &_main {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &_item {
        margin-top: 30px;
        width: 685px;
        height: 200px;
        background: linear-gradient(270deg, #FDFDFF 0%, #F9FAFF 100%);
        border-radius: 4px;
        position: relative;

        &_img {
          width: 140px;
          height: 140px;
          display: inline-block;
          margin: 30px 0 0 40px;

          img {
            width: 100%;
          }
        }

        &_content {
          display: inline-block;
          width: 425px;
          margin-left: 40px;
          position: absolute;
          top: 38px;

          :nth-child(1) {
            height: 30px;
            font-size: 22px;
            font-weight: 600;
            color: #333333;
            line-height: 30px;
            margin-bottom: 10px;
          }

          :nth-child(2) {
            width: 425px;
            height: 84px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 28px;
          }
        }
      }
    }
  }

  &__combination {
    width: 100%;
    height: 517px;
    padding-top: 206px;
    // margin-bottom: 20px;
    position: relative;
    border-radius: 10px;
    background: #F5F6F9;

    &-name {
      // margin: 70px 0 50px 0;
      position: absolute;
      height: 56px;
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 56px;
      // text-align: center;
      left: 50%;
      top: 100px;
      transform: translateX(-50%);
    }

    &-content {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 10px 0 20px 0;
      width: 1400px;
      margin: 0 auto;

      &__list {
        justify-content: center;
        height: 375px;
        // margin-top: 20px;
        padding-top: 40px;
        background: #fff;
        cursor: pointer;
        transition: 0.2s all ease-in;
        width: 320px;
        background: #ffffff;
        box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 0.01), -2px -2px 1px 1px rgba(0, 0, 0, 0.01);
        border-radius: 4px;
        border: 1px solid #ffffff;
        position: relative;

        &_toast {
          position: absolute;
          top: -88px;
          width: 320px;
          height: 78px;
          // position: relative;
          // img {
          // width: 100%;
          // }
          // padding: 23px 0 0 56px;
          background: url('../assets/home-toast.png') no-repeat center center;
          background-size: cover;

          &_message {
            // position: absolute;
            // left: 56px;
            // top: 23px;
            // width: 176px;
            padding: 23px 0 0 56px;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }
        }

        &_img {
          width: 186px;
          height: 186px;
          margin: 0 auto;

          img {
            width: 100%;
            border-radius: 4px;
          }
        }

        &_title {
          margin: 0 auto;
          padding: 20px 0 20px 0;
          width: 164px;
          font-size: 14px;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 234px;
          height: 28px;
          font-size: 20px;
          font-weight: 600;
          color: #333333;
          line-height: 28px;
        }

        &_earnings {
          text-align: center;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          .earin {
            display: inline-block;
            margin: 0 4px;
            font-size: 16px;
            font-weight: 400;
            color: #999999;
          }

          :nth-child(1) {
            content: '';
            width: 14px;
            height: 1px;
            background: #eeeeee;
            display: inline-block;
          }

          :nth-child(3) {
            content: '';
            width: 14px;
            height: 1px;
            background: #eeeeee;
            display: inline-block;
          }
        }

        &_percent {
          text-align: center;
          padding: 13px 80px;
          font-size: 16px;
          color: #666;
          margin: 0 auto;

          :nth-child(1) {
            margin-bottom: 4px;
          }

          &-top {
            height: 20px;
            line-height: 20px;
            width: 100%;
          }

          &-bottom {
            height: 20px;
            line-height: 20px;
            width: 100%;
          }
        }
      }

      &__list:hover {
        background-color: #fff;
        transform: translateY(-2px);
        box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.02), -2px -2px 8px 4px rgba(0, 0, 0, 0.02);
      }
    }

    &-btn {
      margin: 0 auto;
      width: 230px;
      height: 40px;
      background: #406df9;
      border-radius: 4px;
      line-height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      display: flex;
      justify-content: center;
      cursor: pointer;

      &__left {
        display: inline-block;
        position: relative;

        :nth-child(1) {
          display: inline-block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 14px;
          height: 14px;
          margin-right: 2px;

          img {
            width: 100%;
          }
        }

        :nth-child(2) {
          margin-left: 18px;
        }
      }

      &__right {
        display: inline-block;
        width: 180px;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        border: 1px solid #ccc;
        border-radius: 6px;
      }
    }

    &-btn:hover {
      background: #698eff;
    }
  }

  &__four {
    width: 100%;
    height: 809px;
    background: url('../assets/home-four.png') center center / 1920px 809px no-repeat;
    background-size: cover;

    &_main {
      width: 1400px;
      height: 809px;
      margin: 0 auto;

      &_title {
        padding: 100px 0 50px 0;
        text-align: center;
        height: 56px;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 56px;
      }

      &_content {
        display: flex;
        justify-content: space-between;

        &_img {
          width: 613px;
          height: 457px;

          img {
            width: 100%;
          }
        }

        &_text {
          &_item {
            margin-bottom: 40px;

            &_title {
              // width: 184px;
              height: 37px;
              font-size: 26px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #FFFFFF;
              line-height: 37px;
              margin-bottom: 20px;
              display: flex;
              align-items: center;

              span {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #FFFFFF;
                margin-right: 20px;
              }
            }

            &_item {
              width: 682px;
              // height: 56px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 28px;
              padding-left: 28px;
            }
          }
        }
      }
    }
  }

  &__three {
    width: 100%;
    height: 811px;
    background: #F5F6F9;
    position: relative;

    &_main {
      width: 1400px;
      margin: 0 auto;

      &_bottom {
        height: 811px;
        width: 1400px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 281px;

        &_item {
          width: 320px;
          height: 360px;
          background: #FFFFFF;
          box-shadow: 0px 0px 14px 2px rgba(65, 109, 249, 0.06);
          border-radius: 4px;
          padding-top: 64px;

          :nth-child(1) {
            width: 90px;
            height: 90px;
            margin: 0 auto;
          }

          :nth-child(2) {
            padding: 40px 0 30px 0;
            width: 132px;
            height: 30px;
            font-size: 22px;
            font-weight: 600;
            color: #333333;
            line-height: 30px;
            margin: 0 auto;
          }

          :nth-child(3) {
            width: 260px;
            height: 52px;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            margin: 0 auto;
          }
        }
      }

      &_title {
        height: 56px;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 56px;
        position: absolute;
        top: 181px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
      }

      &_bottom {
      }

      &_top {
        position: absolute;
        height: 162px;
        width: 1400px;
        height: 162px;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
        box-shadow: 0px 0px 14px 2px rgba(65, 109, 249, 0.06);
        border-radius: 4px;
        top: -81px;
        display: flex;
        justify-content: center;

        &_item {
          width: 279px;
          text-align: center;
          position: relative;

          :nth-child(1) {
            // width: 109px;
            height: 50px;
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #416DF9;
            line-height: 50px;
            margin-top: 40px;
          }

          :nth-child(2) {
            // width: 203px;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
            margin-top: 10px;
          }

          :nth-child(3) {
            width: 1px;
            height: 60px;
            background: #E8E9ED;
            border-radius: 1px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  &__two {
    width: 1400px;
    margin: 0 auto;
    padding: 100px 0;
    display: flex;
    justify-content: space-between;

    &_left {
      width: 641px;
      height: 200px;

      &_content {
        width: 641px;
        height: 128px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        margin-top: 50px;
      }

      &_line {
        width: 114px;
        height: 6px;
        background: linear-gradient(270deg, #FFFFFF 0%, #416DF9 100%);
        margin-top: 40px;
      }

      &_title {
        width: 522px;
        height: 56px;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 56px;
        margin-top: 93px;
      }
    }

    &_right {
      height: 467px;
      width: 621.8px;

      img {
        width: 100%;
      }
    }
  }
}

>>>.is-active {
  >>>.el-carousel__button {
    width: 44px;
    height: 4px;
    background: #416DF9 !important;
    border-radius: 3px;
  }
}

>>>.el-carousel__button {
  width: 44px;
  height: 4px;
  background: #416DF9;
  border-radius: 3px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
