<template>
  <div>
    <!-- <div style="margin: 0 auto; width: 1400px;"> -->
    <Header @headerTabChange="handleTabChange" />
    <!-- <router-view /> -->
    <transition name="fade" mode="out-in">
      <router-view />
      <!-- <HomePage v-if="(curTab === 1)" />
      <AboutPage v-if="(curTab === 3)" />
      <EsgPage v-if="(curTab === 2)" /> -->
    </transition>
    <!-- </div> -->
  </div>
</template>

<script>
import Header from './components/header.vue'
// import AboutPage from './components/about.vue'
// import EsgPage from './components/esg.vue'
// import HomePage from './components/home.vue'
export default {
  name: 'App',
  components: {
    Header,
    // AboutPage,
    // EsgPage,
    // HomePage
  },
  data() {
    return {
      curTab: 1
    }
  },

  methods: {
    handleTabChange(val) {
      if (val === 4) {
        window.open('https://app.oneesg.cn')
        return
      } else {
        const routerUrl = val === 1 ? 'home' : val === 2 ? 'esg' : 'about'
        this.$router?.push(routerUrl)
        this.curTab = val
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  /* margin: 0 auto; */
  width: 100%;
}
</style>
