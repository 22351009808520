<template>
  <div class="page-esg" v-loading="loading">
    <div class="page-esg__tab">
      <!-- <img :src="require('@/assets/esg.png')" alt=""> -->
      <div class="page-esg__tab_main">
        <!-- <span v-for="s in tabList" :key="s.value">{{s.label}}</span> -->
        <Tab :tabList="tabList" :currTab="currTab" @tabChange:currTab="handleTabChange" />
      </div>
    </div>

    <div class="page-esg__content">
      <div class="page-esg__content_item" v-for="(s, i) in dataList" :key="i" @click="handleToOrigin(s.linkUrl)">
        <div class="page-esg__content_item_main">
          <div class="page-esg__content_item_main_img"><img @load="handleImgLoad(i)" :id="'img' + i" :src="s.coverImgUrl" alt=""></div>
          <div class="page-esg__content_item_main_nolimg" :id="'nolimg' + i"></div>
          <div class="page-esg__content_item_main_content">
            <div class="page-esg__content_item_main_content_title">{{s.title}}</div>
            <div class="page-esg__content_item_main_content_desg">{{s.summar}}</div>
            <div class="page-esg__content_item_main_content_date">
              <span v-if="currTab !== 'esg_books'">{{s.originDesc}}</span>
              <span v-if="currTab !== 'esg_books'">{{s.updateTime && s.updateTime.slice(0, s.updateTime.length - 3)}}</span>
              <span v-if="currTab === 'esg_books'">{{s.auther + ' 著'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-esg__pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" background layout="prev, pager, next, sizes, total" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Tab from './tab.vue'
import { getNewsTab, getNewsList } from '@/request/fetch.js'

export default {
  name: 'HeaderEsg',
  components: {
    Tab
  },
  props: {
    msg: String
  },
  data() {
    return {
      loading: false,
      tabList: [
        { label: 'ESG研究', value: 1 },
        { label: 'ESG资讯', value: 2 },
        { label: '出版物', value: 3 }
      ],

      total: 100,
      pageSize: 10,
      currentPage: 1,
      activeName: 'second',
      currTab: 'esg_research',
      dataList: [],
      imgList: []
    }
  },

  async mounted() {
    // this.loading = true
    const tabRes = await getNewsTab()
    if (tabRes) {
      this.tabList = [...tabRes.data]
      this.currTab = tabRes.data && tabRes.data[0] && tabRes.data[0].code
      console.log(this.currTab)
    }
    const pam = {
      size: this.pageSize,
      page: this.currentPage,
      categoryCode: this.currTab
    }
    const dataList = await getNewsList(pam)
    if (dataList) {
      console.log(dataList)
      this.dataList = [...(dataList?.data?.itemList || [])]
      this.total = dataList?.data?.total || 0
      // this.handleImageLoad()
    }
    // this.loading = false
  },

  methods: {
    handleToOrigin(url) {
      window.open(url)
    },

    handleImgLoad(i) {
      document.getElementById('nolimg' + i).style.opacity = 0
    },

    handleImageLoad() {
      this.dataList.map((s, i) => {
        // this.imgList.push('loaded')
        // if (this.imgList.length === this.dataList.length) {
        //   this.imgList = []
        //   this.loading = false
        // }
        const img = new Image()
        img.src = s.coverImgUrl
        img.onload = function () {
          document.getElementById('img' + i).style.width = `384px`
          document.getElementById('img' + i).style.height = `${384 * (img.height / img.width)}px`
          document.getElementById('nolimg' + i).style.opacity = 0
        }
      })
    },

    async handleTabChange(code) {
      this.currTab = code
      this.currentPage = 1
      this.dataList = []
      await this.handleFetchList()
    },

    async handleFetchList() {
      // this.loading = true
      const res = await getNewsList({
        size: this.pageSize,
        page: this.currentPage,
        categoryCode: this.currTab
      })
      if (res) {
        this.dataList = [...(res?.data?.itemList || [])]
        this.total = res?.data?.total || 0
      }
    },

    async handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      // await this.handleUpdateTableColumn()
      // await this.handleUpdateTableData()
      await this.handleFetchList()
    },
    async handleCurrentChange(val) {
      this.currentPage = val
      // await this.handleUpdateTableColumn()
      // await this.handleUpdateTableData()
      await this.handleFetchList()
    },
  }

  // watch: {
  //   imgList
  // }
}
</script>

<style scoped lang="stylus">
>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #416DF9;
  color: #FFF;
}

.page-esg {
  width: 100%;

  &__pagination {
    margin: 60px 0;
    display: flex;
    justify-content: center;
  }

  &__content {
    width: 1400px;
    margin: 0 auto;
    padding: 80px 0 10px 0;

    &_item {
      height: 216px;
      padding: 60px 0;
      border-bottom: 1px solid #EBECEE;
      cursor: pointer;

      &_main {
        display: flex;
        justify-content: space-between;

        &_img {
          width: 384px;
          height: 216px;
          overflow: hidden;
          margin-left: 10px;
          border-radius: 4px;
          object-fit: cover;

          img {
            width: 100%;
            height: 100%;
            // border-radius: 4px;
            object-fit: cover;
          }
        }

        &_nolimg {
          width: 384px;
          height: 216px;
          background: #ebeef5;
          margin-left: 10px;
          position: absolute;
          border-radius: 4px;
        }

        &_content {
          width: 956px;
          padding-right: 10px;
          text-align: left;
          position: relative;

          &_title {
            width: 956px;
            // height: 80px;
            font-size: 26px;
            font-weight: 500;
            color: #333333;
            line-height: 37px;
            margin-bottom: 20px;
          }

          &_desg {
            width: 956px;
            height: 52px;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          &_date {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            position: absolute;
            bottom: 0;

            :nth-child(1) {
              margin-right: 20px;
            }
          }
        }
      }
    }

    &_item:hover {
      background: #F7F9FF;
      z-index: 999;
    }
  }

  &__tab {
    width: 100%;
    height: 80px;
    position: absolute;
    margin: 0 auto;
    background: url('../assets/esg.jpg') no-repeat center center;
    background-size: cover;

    &_main {
      width: 1400px;
      margin: 0 auto;
      padding-top: 22px;

      span {
        display: inline-block;
        margin-right: 100px;
        height: 36px;
        font-size: 24px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 36px;
      }
    }

    img {
      width: 100%;
    }
  }
}
</style>
